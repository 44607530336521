import React, { useEffect } from "react";
import "./Aboutus.css";

import AOS from "aos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import VissionMission from "../../components/VisionMission/VissionMission";
import Line from "../../components/Line/Line";
import MemberCard from "../../components/MemberCard/MemberCard";
// import MemberCard2 from "../../components/MemberCard/MemberCard2";
// import MemberCard3 from "../../components/MemberCard/MemberCard3";
import ChairmanCard from "../../components/MemberCard/ChairmanCard";
import GMCard from "../../components/MemberCard/GMCard";

import HeroSection from "../../components/Hero/HeroSection";

import data from "../../data/index.json";

import BlueEye from "../../images/BlueEye.png";
import BlueMission from "../../images/blueRocket.png";

import WhatsAppIcon from "../../components/WhatsAppIcon/WhatsAppIcon";

function Aboutus() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const settingsKeyMembers = {
    dots: true,
    infinite: data.AboutUs.key_members.length > 4,  // Set to false if less than 4 items
    speed: 500,
    slidesToShow: Math.min(4, data.AboutUs.key_members.length), // Show only the available items
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(3, data.AboutUs.key_members.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: Math.min(2, data.AboutUs.key_members.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  // const settingsDirectors = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1000,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //     {
  //       breakpoint: 650,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //   ],
  // };

  // const settingsManagers = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //     {
  //       breakpoint: 1000,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //     {
  //       breakpoint: 650,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //   ],
  // };

  return (
    <div className="AboutUs-page">
      <HeroSection>
        <h1 className="hero-text-home">
          <span className="block-text">About Us</span>
        </h1>
      </HeroSection>

      <div className="AboutUs-row-container">
        <VissionMission
          className="vm-card"
          data-aos="fade-up"
          props={{
            image: BlueEye,
            title: "Vision",
            content:
              "Be of excellent contributory to the Fisheries sector by supplying adequate high grade fishing nets and other fishing gears to meet the requirements of the local fishermen so as to achieve enhanced harvesting of sea food",
          }}
        />
        <Line className="vertical-line" data-aos="fade-up" />
        <VissionMission
          className="vm-card"
          data-aos="fade-up"
          props={{
            image: BlueMission,
            title: "Mission",
            content:
              "Manufacturing and supplying standard quality of fishnets and other fishing gears to meet seasonal demand in order to encourage fishing activities and seafood production of the country.",
          }}
        />
      </div>

      <div className="AboutUs-Member-container">
        <div className="AboutUs-section-content-wrapper">
          <h2 className="AboutUs-section-title" data-aos="fade-up">
            Key Members
          </h2>
          <div className="keyMembers-carousel" data-aos="fade-up">
            <Slider {...settingsKeyMembers} className="keyMember-slider">
              {data.AboutUs.key_members.map((member) => {
                return (
                  <MemberCard
                    className="keyMember-card"
                    key={member.id}
                    props={{
                      image: member.imageSRC,
                      member_name: member.name,
                      member_post: member.post,
                      member_ministry: member.ministry,
                      member_tel: member.tel,
                      member_fax: member.fax,
                      member_mail: member.mail,
                    }}
                  />
                );
              })}
            </Slider>
          </div>
        </div>
      </div>

      <div className="AboutUs-Member-container">
        <div className="AboutUs-section-content-wrapper">
          <h2 className="AboutUs-section-title" data-aos="fade-up">
            Board of Directors
          </h2>
          <div className="AboutUs-Single-Card" data-aos="fade-up">
            {
              <ChairmanCard
                className="Chairman-card"
                props={{
                  image: data.AboutUs.Chairman.imageSRC,
                  name: data.AboutUs.Chairman.name,
                  name2: data.AboutUs.Chairman.name2,
                  post: data.AboutUs.Chairman.post,
                  tel: data.AboutUs.Chairman.tel,
                  mail: data.AboutUs.Chairman.mail,
                }}
              />
            }
          </div>
          {/*<div className="Directors-carousel" data-aos="fade-up">
            <Slider {...settingsDirectors} className="Directors-slider">
              {data.AboutUs.Directors.map((director) => {
                return (
                  <MemberCard2
                    className="Director-card"
                    key={director.id}
                    props={{
                      image: director.imageSRC,
                      member_name: director.name,
                      member_post: director.post,
                      member_tel: director.tel,
                      member_mail: director.mail,
                    }}
                  />
                );
              })}
            </Slider>
          </div>*/}
        </div>
      </div>

      <div className="AboutUs-Member-container">
        <div className="AboutUs-section-content-wrapper">
          <h2 className="AboutUs-section-title" data-aos="fade-up">
            Management Team
          </h2>
          <div className="AboutUs-Single-Card" data-aos="fade-up">
            {
              <GMCard
                className="GM-card"
                props={{
                  name: data.AboutUs.GeneralManager.name,
                  post: data.AboutUs.GeneralManager.post,
                  tel: data.AboutUs.GeneralManager.tel,
                  mail: data.AboutUs.GeneralManager.mail,
                }}
              />
            }
          </div>
          {/*<div className="Management-carousel" data-aos="fade-up">
            <Slider {...settingsManagers} className="Management-slider">
              {data.AboutUs.Managers.map((manager) => {
                return (
                  <MemberCard3
                    className="Manager-card"
                    key={manager.id}
                    props={{
                      member_name: manager.name,
                      member_post: manager.post,
                    }}
                  />
                );
              })}
            </Slider>
          </div>*/}
        </div>
      </div>
      <WhatsAppIcon />
    </div>
  );
}

export default Aboutus;
