import React, { useEffect } from 'react';
import AOS from 'aos';
import "./Announcements.css";

// import { BsFillBookmarkFill } from "react-icons/bs";
// import { GrAnnounce } from "react-icons/gr";
// import { FaRegFilePdf } from "react-icons/fa6";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



import HeroSection from '../../components/Hero/HeroSection';
import WhatsAppIcon from '../../components/WhatsAppIcon/WhatsAppIcon';

function Announcements() {

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);

  return (
    <div className="announcement-page">
      <HeroSection>
        <h1 className='hero-text-home'><span className='block-text'>Announcement</span></h1>
      </HeroSection>
      {/*<h2 className="noTenders">No tenders or announcements available at the moment. Keep in touch !</h2>*/}
      {/*
      <h2 className="Announcement-section-title" data-aos="fade-up">Tenders</h2>
      <div className="tender-container">
        <ul className="tender-list">
          <p className="tender-item" data-aos="fade-up">
            <span className="bookmark-icon">
              <BsFillBookmarkFill size={25} />
            </span>
            About the tender detail should include here. About the tender detail
            should include here.
          </p>

          <p className="tender-item" data-aos="fade-up">
            <span className="bookmark-icon">
              <BsFillBookmarkFill size={25} />
            </span>
            About the tender detail should include here. About the tender detail
            should include here.
          </p>

          <p className="tender-item" data-aos="fade-up">
            <span className="bookmark-icon">
              <BsFillBookmarkFill size={25} />
            </span>
            About the tender detail should include here. About the tender detail
            should include here.
          </p>
          <p className="tender-item" data-aos="fade-up">
            <span className="bookmark-icon">
              <BsFillBookmarkFill size={25} />
            </span>
            About the tender detail should include here. About the tender detail
            should include here.
          </p>

        </ul>
      </div>
    */}

      {/*

      <h2 className="Announcement-section-title" data-aos="fade-up">

        <div className="announce-icon">
          <GrAnnounce size={40} />
        </div>
        Announcements
      </h2>*/}

      <div className="announcement-container">

        <div className="announcement-card" data-aos="fade-up">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FAnnouncement%2Fbidding.jpg?alt=media&token=a4515baf-2924-4bfd-a8ff-a2367ef6801c"
            className="announcement-image"
            alt="announcement-2"
          />
          <div className="announcement-content">
            <h3 className="announcement-title">Invitation for Tender – Supply of 100% Nylon PA6 High Tenacity Multifilament Yarn</h3>
            <p className="announcement-details">
              Northsea Ltd invites sealed bids for the supply of 100% Nylon (PA6) High Tenacity Multifilament Yarn. Interested and eligible bidders may purchase tender documents from Northsea Ltd, Ministry of Fisheries Building, Colombo 10.
            </p>
            <p className="announcement-details" style={{ marginBottom: "12%" }}>
              For full details, <a href="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FAnnouncement%2FTender%20-%20Documents%20(004).pdf?alt=media&token=854cdbaa-a5ae-4093-a633-a79898c8e4a4" target="_blank" rel="noopener noreferrer">Click here</a>.
            </p>
            <p className="announcement-details">
              Submission deadline: April 7, 2025, at 3:00 PM.
            </p>
            <div className="announcement-date">March 20, 2025</div>
          </div>
        </div>

        <div className="announcement-card" data-aos="fade-up">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FAnnouncement%2Fhiring.jpeg?alt=media&token=03fd42a8-2798-4055-8cad-9dbdc5de4491"
            className="announcement-image"
            alt="announcement-1"
          />
          <div className="announcement-content">
            <h3 className="announcement-title">Marketing Internship Opportunity</h3>
            <p className="announcement-details">
              North Sea Limited - Lunuwila is looking for a Marketing Intern to join our dynamic team! If you are currently pursuing a degree in Marketing, Business Administration, or a related field, this is a great opportunity to gain hands-on experience. Responsibilities include market research, sales material development, and assisting in marketing operations.
            </p>
            <p className="announcement-details" style={{ marginBottom: "12%" }}>
              For full details, <a href="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FAnnouncement%2FMarketing%20Intern.pdf?alt=media&token=18a0724c-409f-41f2-b391-7314211bc665" target="_blank" rel="noopener noreferrer">Click here</a>.
            </p>
            <p className="announcement-details">
              Send your resume to <a href="mailto:lunuwila.fm@gmail.com">lunuwila.fm@gmail.com</a>
            </p>
            <div className="announcement-date">February 28, 2025</div>
          </div>
        </div>

        <div className="announcement-card" data-aos="fade-up">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FAnnouncement%2Fhiring.jpeg?alt=media&token=03fd42a8-2798-4055-8cad-9dbdc5de4491"
            className="announcement-image"
            alt="announcement-2"
          />
          <div className="announcement-content">
            <h3 className="announcement-title">Accounts Internship Opportunity</h3>
            <p className="announcement-details">
              Join North Sea Limited - Lunuwila as an Accounts Intern! We are seeking undergraduates or recent graduates in Business Management, Finance, or Accounting. Gain valuable experience in financial reporting, data entry, and account management while working with our finance team.
            </p>
            <p className="announcement-details" style={{ marginBottom: "12%" }}>
              For full details, <a href="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FAnnouncement%2Faccounts%20intern.pdf?alt=media&token=436b5137-65ab-450e-9bbf-de5c0de16f51" target="_blank" rel="noopener noreferrer">Click here</a>.
            </p>
            <p className="announcement-details">
              Send your resume to <a href="mailto:lunuwila.fm@gmail.com">lunuwila.fm@gmail.com</a>
            </p>
            <div className="announcement-date">February 28, 2025</div>
          </div>
        </div>

        {/* 
        <div className="announcement-card" data-aos="fade-up">
          <img
            src="https://drive.google.com/uc?export=view&id=1hlab_XVThsqw5eeK-KEdTcbkZybopJnJ"
            className="announcement-image"
            alt="announcement-2"
          />
          <div className="announcement-content">
            <h3 className="announcement-title">Executive manager positions available</h3>
            <p className="announcement-details">
              Announcement details include here. Announcement details include
              here.
            </p>
            <div className="announcement-date">September 29, 2023</div>
          </div>
        </div>

        <div className="announcement-card" data-aos="fade-up">
          <img
            src="https://drive.google.com/uc?export=view&id=1hlab_XVThsqw5eeK-KEdTcbkZybopJnJ"
            className="announcement-image"
            alt="announcement-3"
          />
          <div className="announcement-content">
            <h3 className="announcement-title">We are hiring</h3>
            <p className="announcement-details">
              Announcement details include here. Announcement details include
              here.
            </p>
            <div className="announcement-date">September 29, 2023</div>
          </div>

        </div>
        */}
      </div>

      <WhatsAppIcon />

    </div>
  );
}

export default Announcements;
